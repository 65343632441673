import { generatePath, matchPath } from 'react-router-dom';
import { Paths } from '../../routes/RoutingConstants';
const pageNumberRegex = /\/page\/\d+\/?$/;
const updatePageNumberInPath = (currentPath, newPageNumber) => {
  return currentPath.replace(pageNumberRegex, `/page/${newPageNumber}`);
};
const addPageNumberToPath = (currentPath, newPageNumber) => {
  const maybeSlash = currentPath.endsWith('/') ? '' : '/';
  return `${currentPath}${maybeSlash}page/${newPageNumber}`;
};
export const getNewPagePathName = (currentPath, newPageNumber) => {
  if (newPageNumber === 1) {
    return currentPath.replace(pageNumberRegex, '');
  }
  if (pageNumberRegex.test(currentPath)) {
    //page number already exists in path
    return updatePageNumberInPath(currentPath, newPageNumber);
  }
  return addPageNumberToPath(currentPath, newPageNumber); //page number doesn't exist in path, add it
};
export const removePathFilterFromPathName = pathName => {
  var _pathInfo$params;
  const isPathIncludesPagination = pageNumberRegex.test(pathName);
  if (!isPathIncludesPagination) return Paths.CATALOG_PAGE;
  const pathInfo = matchPath(pathName, {
    path: Paths.CATALOG_PAGE_WITH_PATH_FILTER_AND_PAGINATION
  });
  if (!(pathInfo !== null && pathInfo !== void 0 && (_pathInfo$params = pathInfo.params) !== null && _pathInfo$params !== void 0 && _pathInfo$params.pageNumber)) return pathName;
  return generatePath(Paths.CATALOG_PAGE_WITH_PAGINATION, {
    pageNumber: pathInfo.params.pageNumber
  });
};