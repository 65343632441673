import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import usageTracker from '../../utils/usageTracker';
import { getNewPagePathName } from './publicCatalogRouting';
export const useSetPageNumberInURL = () => {
  const history = useHistory();
  const location = useLocation();
  return pageNumber => {
    const newPathname = getNewPagePathName(location.pathname, pageNumber);
    if (!newPathname) return;
    history.push({
      pathname: newPathname,
      search: location.search
    });
  };
};
export const useGetPageNumber = () => {
  const {
    pageNumber: pageNumberString = '1'
  } = useParams();
  const pageNumber = Number(pageNumberString);
  // if pageNumber param doesnt exist or is NaN, default to 1
  const validPageNumber = pageNumber && !isNaN(pageNumber) ? pageNumber : 1;
  return validPageNumber;
};
export const useGetResultsDisplay = (pageSize, totalResults) => {
  const pageNumber = useGetPageNumber();
  const minResult = (pageNumber - 1) * pageSize + 1;
  const maxResult = Math.min(pageNumber * pageSize, totalResults);
  return {
    minResult,
    maxResult
  };
};
export const usePagination = isRouterPagination => {
  const pageNumberFromURL = useGetPageNumber();
  const [localPageNumber, setLocalPageNumber] = useState(isRouterPagination ? pageNumberFromURL : 1);
  const setPageNumberInURL = useSetPageNumberInURL();
  // if isRouterPagination is true, use the pageNumber from the URL, else use internal state
  const pageNumberToUse = isRouterPagination ? pageNumberFromURL : localPageNumber;
  const setPageNumber = newPageNumber => {
    if (isRouterPagination) {
      setPageNumberInURL(newPageNumber);
    } else {
      setLocalPageNumber(newPageNumber);
    }
    usageTracker.track('interaction', {
      action: 'catalog-results-change-page',
      value: `${newPageNumber}`
    });
  };
  return [pageNumberToUse, setPageNumber];
};