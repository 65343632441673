/**
 * This function provides a consistent way to access the global object across
 * different environments, including both Node.js and web browsers, by using
 * `globalThis`. This approach ensures compatibility and simplifies accessing
 * global properties in diverse runtime contexts.
 * e.g. window in browser, global in node, etc.
 *
 * @returns {Object} An object that may contain the properties of `globalThis`,
 * if available.
 */
export function getGlobal() {
  return typeof globalThis !== 'undefined' && globalThis || {};
}
export function getFetch() {
  return getGlobal().fetch;
}