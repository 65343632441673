export const footerLinks = {
  HUBSPOT_USER_GROUPS: {
    i18nKey: 'academyPublic.publicFooter.hubSpotUserGroups',
    hrefs: {
      en: 'https://www.hubspot.com/hubspot-user-groups/'
    }
  },
  HUBSPOT_COMMUNITY: {
    i18nKey: 'academyPublic.publicFooter.hubSpotCommunity',
    hrefs: {
      en: 'https://community.hubspot.com/',
      de: 'https://community.hubspot.com/t5/HubSpot-Academy-auf-Deutsch/bd-p/academy_de/',
      ja: 'https://community.hubspot.com/t5/Academy/ct-p/academy/?profile.language=ja',
      es: 'https://community.hubspot.com/t5/HubSpot-Academy-en-Español/bd-p/academy_es/',
      pt: 'https://community.hubspot.com/t5/Academy/ct-p/academy/?profile.language=pt-br',
      fr: 'https://community.hubspot.com/t5/HubSpot-Academy/bd-p/Academy_fr/'
    }
  },
  SOFTWARE_TRAINING: {
    i18nKey: 'academyPublic.publicFooter.softwareTraining',
    hrefs: {
      de: 'https://academy.hubspot.de/marketing-sales-tool-training/',
      ja: 'https://community.hubspot.com/t5/Academy/ct-p/academy/?profile.language=ja',
      es: 'https://community.hubspot.com/t5/HubSpot-Academy-en-Español/bd-p/academy_es/',
      pt: 'https://community.hubspot.com/t5/Academy/ct-p/academy/?profile.language=pt-br',
      fr: 'https://community.hubspot.com/t5/HubSpot-Academy/bd-p/Academy_fr/'
    }
  },
  SUPPORT_FORUM: {
    i18nKey: 'academyPublic.publicFooter.supportForum',
    hrefs: {
      en: 'https://help.hubspot.com/',
      de: 'https://help.hubspot.com/de/',
      ja: 'https://help.hubspot.com/ja/',
      es: 'https://help.hubspot.com/es/',
      pt: 'https://help.hubspot.com/pt/',
      fr: 'https://help.hubspot.com/fr/'
    }
  },
  EDUCATION_PARTNERS: {
    i18nKey: 'academyPublic.publicFooter.educationPartners',
    hrefs: {
      en: 'https://academy.hubspot.com/education-partner-program/',
      es: 'https://academy.hubspot.com/es/education-partner-program/'
    }
  },
  CERTIFIED_TRAINERS: {
    i18nKey: 'academyPublic.publicFooter.certifiedTrainers',
    hrefs: {
      en: 'https://academy.hubspot.com/hubspot-certified-trainer/'
    }
  },
  HUBSPOT_PARTNERS: {
    i18nKey: 'academyPublic.publicFooter.hubSpotPartners',
    hrefs: {
      en: 'https://www.hubspot.com/partners/',
      de: 'https://www.hubspot.de/partners/',
      ja: 'https://www.hubspot.jp/partners/',
      es: 'https://www.hubspot.es/partners/',
      fr: 'https://www.hubspot.fr/partners/'
    }
  },
  FREQUENT_QUESTIONS: {
    i18nKey: 'academyPublic.publicFooter.frequentQuestions',
    hrefs: {
      en: 'https://academy.hubspot.com/certification-support/',
      de: 'https://academy.hubspot.de/certification-support/',
      ja: 'https://academy.hubspot.jp/certification-support/',
      es: 'https://academy.hubspot.com/es/certification-support/',
      pt: 'https://academy.hubspot.com/pt/certification-support/',
      fr: 'https://academy.hubspot.fr/certification-support/'
    }
  },
  CERTIFICATIONS: {
    i18nKey: 'academyPublic.publicFooter.certifications',
    hrefs: {
      en: 'https://academy.hubspot.com/certification-overview/',
      de: 'https://academy.hubspot.de/certification-overview/',
      ja: 'https://academy.hubspot.jp/certification-overview/',
      es: 'https://academy.hubspot.com/es/certification-overview/',
      pt: 'https://academy.hubspot.com/pt/certification-overview/',
      fr: 'https://academy.hubspot.fr/certification-overview/'
    }
  },
  DESIGNER_DOCS: {
    i18nKey: 'academyPublic.publicFooter.designerDocs',
    hrefs: {
      en: 'https://designers.hubspot.com/'
    }
  },
  DEVELOPER_DOCS: {
    i18nKey: 'academyPublic.publicFooter.developerDocs',
    hrefs: {
      en: 'https://developers.hubspot.com/',
      de: 'https://developers.hubspot.de/',
      ja: 'https://developers.hubspot.jp/',
      es: 'https://developers.hubspot.es/',
      pt: 'https://br.developers.hubspot.com/',
      fr: 'https://developers.hubspot.fr/'
    }
  },
  USER_GUIDES: {
    i18nKey: 'academyPublic.publicFooter.userGuides',
    hrefs: {
      en: 'https://help.hubspot.com/',
      de: 'https://help.hubspot.com/de/',
      ja: 'https://help.hubspot.com/ja/',
      es: 'https://help.hubspot.com/es/',
      pt: 'https://help.hubspot.com/pt/',
      fr: 'https://help.hubspot.com/fr/'
    }
  },
  CUSTOMERS_BLOG: {
    i18nKey: 'academyPublic.publicFooter.customersBlog',
    hrefs: {
      en: 'https://blog.hubspot.com/customers/'
    }
  },
  BLOG: {
    i18nKey: 'academyPublic.publicFooter.blog',
    hrefs: {
      en: 'https://blog.hubspot.com/',
      de: 'https://blog.hubspot.de/',
      ja: 'https://blog.hubspot.jp/',
      es: 'https://blog.hubspot.es/',
      pt: 'https://br.hubspot.com/blog/',
      fr: 'https://blog.hubspot.fr/'
    }
  },
  PODCAST_IN_SPANISH: {
    i18nKey: 'academyPublic.publicFooter.podcastInSpanish',
    hrefs: {
      es: 'https://www.hubspot.es/podcasts/skill-up/'
    }
  },
  HUBSPOT_TEMPLATES: {
    i18nKey: 'academyPublic.publicFooter.hubSpotTemplates',
    hrefs: {
      en: 'https://ecosystem.hubspot.com/marketplace/website/',
      de: 'https://ecosystem.hubspot.com/de/marketplace/website/',
      ja: 'https://ecosystem.hubspot.com/ja/marketplace/website/',
      es: 'https://ecosystem.hubspot.com/es/marketplace/website/',
      pt: 'https://ecosystem.hubspot.com/pt/marketplace/website/',
      fr: 'https://ecosystem.hubspot.com/fr/marketplace/website/'
    }
  },
  JOIN_A_USER_GROUP: {
    i18nKey: 'academyPublic.publicFooter.joinAUserGroup',
    hrefs: {
      en: 'https://academy.hubspot.com/hubspot-user-groups/join-a-hug/'
    }
  },
  GET_A_FREE_WEBSITE_REPORT: {
    i18nKey: 'academyPublic.publicFooter.getAFreeWebsiteReport',
    hrefs: {
      en: 'https://website.grader.com/',
      de: 'https://website.grader.com/de/',
      ja: 'https://website.grader.com/jp/',
      es: 'https://website.grader.com/es/',
      pt: 'https://website.grader.com/pt/',
      fr: 'https://website.grader.com/fr/'
    }
  },
  FREE_TOOLS_AND_GENERATORS: {
    i18nKey: 'academyPublic.publicFooter.freeToolsAndGenerators',
    hrefs: {
      en: 'https://www.hubspot.com/free-business-tools/',
      de: 'https://www.hubspot.de/free-business-tools/',
      ja: 'https://www.hubspot.jp/free-business-tools/',
      es: 'https://www.hubspot.es/free-business-tools/',
      pt: 'https://br.hubspot.com/free-business-tools/',
      fr: 'https://www.hubspot.fr/free-business-tools/'
    }
  },
  CREATE_A_BUYER_PERSONA: {
    i18nKey: 'academyPublic.publicFooter.createABuyerPersona',
    hrefs: {
      en: 'https://www.hubspot.com/make-my-persona/',
      de: 'https://www.hubspot.de/make-my-persona/'
    }
  },
  MARKETING_LIBRARY: {
    i18nKey: 'academyPublic.publicFooter.marketingLibrary',
    hrefs: {
      en: 'https://www.hubspot.com/resources/',
      de: 'https://www.hubspot.de/marketing-bibliothek/',
      ja: 'https://www.hubspot.jp/marketing-library/',
      es: 'https://www.hubspot.es/biblioteca-marketing/',
      pt: 'https://br.hubspot.com/resources/',
      fr: 'https://www.hubspot.fr/marketing-bibliothèque/'
    }
  },
  BUSINESS_TEMPLATES: {
    i18nKey: 'academyPublic.publicFooter.businessTemplates',
    hrefs: {
      en: 'https://www.hubspot.com/business-templates/',
      de: 'https://www.hubspot.de/business-templates/',
      ja: 'https://www.hubspot.jp/business-templates/',
      es: 'https://www.hubspot.es/business-templates/',
      pt: 'https://br.hubspot.com/business-templates/',
      fr: 'https://www.hubspot.fr/business-templates/'
    }
  },
  EMAIL_SIGNATURE_GENERATOR: {
    i18nKey: 'academyPublic.publicFooter.emailSignatureGenerator',
    hrefs: {
      en: 'https://www.hubspot.com/email-signature-generator/',
      de: 'https://www.hubspot.de/email-signature-generator/',
      ja: 'https://www.hubspot.jp/email-signature-generator/',
      es: 'https://www.hubspot.es/email-signature-generator/',
      pt: 'https://br.hubspot.com/email-signature-generator/',
      fr: 'https://www.hubspot.fr/email-signature-generator/'
    }
  },
  GROWTH_GRADER: {
    i18nKey: 'academyPublic.publicFooter.growthGrader',
    hrefs: {
      en: 'https://growthgrader.hubspot.com/',
      de: 'https://growthgrader.hubspot.com/de/',
      ja: 'https://growthgrader.hubspot.com/ja/',
      es: 'https://growthgrader.hubspot.com/es/',
      pt: 'https://growthgrader.hubspot.com/pt/',
      fr: 'https://growthgrader.hubspot.com/fr/'
    }
  },
  ROI_CALCULATOR: {
    i18nKey: 'academyPublic.publicFooter.roiCalculator',
    hrefs: {
      en: 'https://www.hubspot.com/roi-calculator/marketing/',
      de: 'https://www.hubspot.de/roi-calculator/marketing/',
      ja: 'https://www.hubspot.jp/roi-calculator/marketing/',
      es: 'https://www.hubspot.es/roi-calculator/marketing/',
      pt: 'https://br.hubspot.com/roi-calculator/marketing/',
      fr: 'https://www.hubspot.fr/roi-calculator/marketing/'
    }
  },
  VIDEO_WEBINARS: {
    i18nKey: 'academyPublic.publicFooter.videoWebinars',
    hrefs: {
      es: 'https://academy.hubspot.com/es/webinars/',
      pt: 'https://academy.hubspot.com/pt/webinars/'
    }
  },
  PRIVACY_POLICY: {
    i18nKey: 'academyPublic.publicFooter.privacy',
    hrefs: {
      en: 'https://legal.hubspot.com/privacy-policy',
      de: 'https://legal.hubspot.com/de/privacy-policy',
      ja: 'https://legal.hubspot.com/ja/privacy-policy',
      es: 'https://legal.hubspot.com/es/privacy-policy',
      pt: 'https://legal.hubspot.com/br/privacy-policy',
      fr: 'https://legal.hubspot.com/fr/privacy-policy'
    }
  },
  LEGAL_STUFF: {
    i18nKey: 'academyPublic.publicFooter.legal',
    hrefs: {
      en: 'https://legal.hubspot.com/legal-stuff',
      de: 'https://legal.hubspot.com/de/legal-stuff',
      ja: 'https://legal.hubspot.com/ja/legal-stuff',
      es: 'https://legal.hubspot.com/es/legal-stuff',
      pt: 'https://legal.hubspot.com/br/legal-stuff',
      fr: 'https://legal.hubspot.com/fr/legal-stuff'
    }
  },
  SUPPORT_BUTTON: {
    i18nKey: 'academyPublic.publicFooter.hubSpotSupport',
    hrefs: {
      en: 'https://help.hubspot.com/',
      de: 'https://help.hubspot.com/de/',
      ja: 'https://help.hubspot.com/ja/',
      es: 'https://help.hubspot.com/es/',
      pt: 'https://help.hubspot.com/pt/',
      fr: 'https://help.hubspot.com/fr/'
    }
  }
};
export const communityLinks = {
  en: [footerLinks.HUBSPOT_USER_GROUPS, footerLinks.SUPPORT_FORUM, footerLinks.EDUCATION_PARTNERS, footerLinks.CERTIFIED_TRAINERS],
  de: [footerLinks.HUBSPOT_COMMUNITY, footerLinks.SUPPORT_FORUM, footerLinks.SOFTWARE_TRAINING, footerLinks.HUBSPOT_USER_GROUPS],
  ja: [footerLinks.HUBSPOT_COMMUNITY, footerLinks.SUPPORT_FORUM, footerLinks.EDUCATION_PARTNERS, footerLinks.CERTIFIED_TRAINERS, footerLinks.HUBSPOT_USER_GROUPS],
  es: [footerLinks.HUBSPOT_COMMUNITY, footerLinks.SUPPORT_FORUM, footerLinks.EDUCATION_PARTNERS, footerLinks.HUBSPOT_PARTNERS],
  pt: [footerLinks.HUBSPOT_COMMUNITY, footerLinks.SUPPORT_FORUM, footerLinks.EDUCATION_PARTNERS, footerLinks.CERTIFIED_TRAINERS, footerLinks.HUBSPOT_USER_GROUPS],
  fr: [footerLinks.HUBSPOT_COMMUNITY, footerLinks.SUPPORT_FORUM, footerLinks.EDUCATION_PARTNERS, footerLinks.HUBSPOT_USER_GROUPS]
};
export const resourcesLinks = {
  en: [footerLinks.FREQUENT_QUESTIONS, footerLinks.CERTIFICATIONS, footerLinks.DESIGNER_DOCS, footerLinks.DEVELOPER_DOCS, footerLinks.USER_GUIDES, footerLinks.CUSTOMERS_BLOG],
  de: [footerLinks.FREQUENT_QUESTIONS, footerLinks.CERTIFICATIONS, footerLinks.DEVELOPER_DOCS, footerLinks.HUBSPOT_TEMPLATES, footerLinks.BLOG],
  ja: [footerLinks.FREQUENT_QUESTIONS, footerLinks.CERTIFICATIONS, footerLinks.DEVELOPER_DOCS, footerLinks.BLOG],
  es: [footerLinks.FREQUENT_QUESTIONS, footerLinks.CERTIFICATIONS, footerLinks.BLOG, footerLinks.PODCAST_IN_SPANISH, footerLinks.DEVELOPER_DOCS],
  pt: [footerLinks.FREQUENT_QUESTIONS, footerLinks.CERTIFICATIONS, footerLinks.DEVELOPER_DOCS, footerLinks.BLOG],
  fr: [footerLinks.FREQUENT_QUESTIONS, footerLinks.CERTIFICATIONS, footerLinks.DEVELOPER_DOCS, footerLinks.BLOG]
};
export const supportAndToolsLinks = {
  en: [footerLinks.HUBSPOT_PARTNERS, footerLinks.JOIN_A_USER_GROUP, footerLinks.GET_A_FREE_WEBSITE_REPORT, footerLinks.HUBSPOT_TEMPLATES, footerLinks.FREE_TOOLS_AND_GENERATORS],
  de: [footerLinks.HUBSPOT_PARTNERS, footerLinks.GET_A_FREE_WEBSITE_REPORT, footerLinks.CREATE_A_BUYER_PERSONA, footerLinks.MARKETING_LIBRARY, footerLinks.BUSINESS_TEMPLATES],
  ja: [footerLinks.HUBSPOT_PARTNERS, footerLinks.GET_A_FREE_WEBSITE_REPORT, footerLinks.HUBSPOT_TEMPLATES, footerLinks.MARKETING_LIBRARY],
  es: [footerLinks.GET_A_FREE_WEBSITE_REPORT, footerLinks.EMAIL_SIGNATURE_GENERATOR, footerLinks.CREATE_A_BUYER_PERSONA, footerLinks.GROWTH_GRADER, footerLinks.ROI_CALCULATOR, footerLinks.BUSINESS_TEMPLATES, footerLinks.HUBSPOT_TEMPLATES],
  pt: [footerLinks.HUBSPOT_PARTNERS, footerLinks.GET_A_FREE_WEBSITE_REPORT, footerLinks.HUBSPOT_TEMPLATES, footerLinks.MARKETING_LIBRARY, footerLinks.VIDEO_WEBINARS],
  fr: [footerLinks.GET_A_FREE_WEBSITE_REPORT, footerLinks.HUBSPOT_TEMPLATES, footerLinks.MARKETING_LIBRARY, footerLinks.BUSINESS_TEMPLATES]
};
export const socialLinks = [{
  name: 'Facebook',
  icon: 'socialBlockFacebook',
  hrefs: {
    en: 'https://facebook.com/thehubspotacademy/',
    de: 'https://www.facebook.com/HubspotDeutschland/',
    ja: 'https://www.facebook.com/HubSpotJapan/',
    es: 'https://www.facebook.com/HubSpotEspanol/',
    fr: 'https://www.facebook.com/HubSpotFrance/'
  }
}, {
  name: 'Instagram',
  icon: 'socialInstagram',
  hrefs: {
    en: 'https://www.instagram.com/hubspotacademy/',
    de: 'https://www.instagram.com/hubspotdach/'
  }
}, {
  name: 'YouTube',
  icon: 'socialBlockYoutubeplay',
  hrefs: {
    en: 'https://www.youtube.com/hubspot/',
    de: 'https://www.youtube.com/channel/UCInS7wB5bD-mgLz-shTJTCw/',
    ja: 'https://www.youtube.com/channel/UCm3Hl6Zz4Ua_2x-NIFpVDPQ/',
    es: 'https://www.youtube.com/channel/UCnEpR8Tczb_PrORMc3QI8Pg/featured/',
    fr: 'https://www.youtube.com/HubSpotFrance/'
  }
}, {
  name: 'X (Twitter)',
  icon: 'socialBlockX',
  hrefs: {
    en: 'https://twitter.com/hubspotacademy/',
    de: 'https://twitter.com/HubSpotDACH/',
    ja: 'https://twitter.com/HubSpotJapan/',
    es: 'https://twitter.com/HubSpotEspanol/',
    fr: 'https://twitter.com/HubSpotFrance/'
  }
}, {
  name: 'LinkedIn',
  icon: 'socialBlockLinkedin',
  hrefs: {
    en: 'https://www.linkedin.com/school/hubspot-academy/'
  }
}];