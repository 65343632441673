module.exports = {
  "/": {
    "name": "homepage",
    "success": {
      "homepage loaded": [
        "HOMEPAGE_LOAD"
      ]
    },
    "error": [
      "HOMEPAGE_LOAD_FAILURE"
    ]
  },
  "/courses": {
    "name": "catalog page",
    "success": {
      "catalog results loaded": [
        "CATALOG_PAGE_LOAD",
        "CATALOG_RESULTS_LOADED"
      ],
      "catalog empty page loaded": [
        "CATALOG_PAGE_LOAD_EMPTY"
      ]
    },
    "error": [
      "CATALOG_PAGE_LOAD_FAILURE",
      "CATALOG_FILTERS_LOAD_FAILURE"
    ]
  },
  "/certification-overview": {
    "name": "certification overview page",
    "success": {
      "certification overview page loaded": [
        "CERTIFICATION_OVERVIEW_PAGE_LOAD"
      ]
    },
    "error": [
      "CERTIFICATION_OVERVIEW_PAGE_LOAD_FAILURE"
    ]
  },
  "/courses/:pagePath": {
    "name": "course landing page",
    "success": {
      "course landing page loaded": [
        "COURSE_LANDING_PAGE_LOAD"
      ],
      "course landing page not found": [
        "COURSE_LANDING_PAGE_NOT_FOUND"
      ],
      "course landing page forbidden": [
        "COURSE_LANDING_PAGE_FORBIDDEN"
      ]
    },
    "error": [
      "COURSE_LANDING_PAGE_LOAD_FAILURE"
    ]
  },
  "/lessons/:pagePath": {
    "name": "lesson landing page",
    "success": {
      "lesson landing page loaded": [
        "LESSON_LANDING_PAGE_LOAD"
      ],
      "lesson landing page not found": [
        "LESSON_LANDING_PAGE_NOT_FOUND"
      ],
      "lesson landing page forbidden": [
        "LESSON_LANDING_PAGE_FORBIDDEN"
      ]
    },
    "error": [
      "LESSON_LANDING_PAGE_LOAD_FAILURE"
    ]
  }
};