module.exports = {
  "videoUsage": {
    "name": "video-usage",
    "class": "usage",
    "properties": {
      "type": [
        "rendered",
        "loaded",
        "started",
        "engaged",
        "finished",
        "minutes-watched"
      ],
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": "string",
      "viewDuration": {
        "type": "number",
        "isOptional": true
      },
      "autoPlay": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "academy-public-ui",
    "version": "1"
  },
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "academy-public-ui",
    "version": "1"
  },
  "navInteraction": {
    "name": "public academy nav interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "href": {
        "type": "string",
        "isOptional": true
      },
      "searchQuery": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "academy-public-ui",
    "version": "1"
  },
  "interaction": {
    "name": "public academy interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "href": {
        "type": "string",
        "isOptional": true
      },
      "contentType": {
        "type": "string",
        "isOptional": true
      },
      "appearsIn": {
        "type": "string",
        "isOptional": true
      },
      "organisation": {
        "type": "string",
        "isOptional": true
      },
      "organisationUrl": {
        "type": "string",
        "isOptional": true
      },
      "professorId": {
        "type": "number",
        "isOptional": true
      },
      "lessonId": {
        "type": "number",
        "isOptional": true
      },
      "trackId": {
        "type": "number",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "academy-public-ui",
    "version": "1"
  }
};