import { getPublicLang } from 'academy-common-lib/utils/url';
import { usePublicCatalogResults } from '../publicCatalog/publicCatalogQueries';
export const useCertificationsResults = () => {
  const {
    publicCatalogData,
    publicCatalogError,
    publicCatalogLoading
  } = usePublicCatalogResults({
    contentTypes: ['TRACK'],
    pageLength: 12,
    offset: {
      cursor: 0
    },
    featuredCertificationsOnly: true,
    language: getPublicLang() || 'en'
  });
  return {
    certificationsData: publicCatalogData,
    certificationsError: publicCatalogError,
    certificationsLoading: publicCatalogLoading
  };
};