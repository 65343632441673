import { pathFilters } from '../features/publicCatalog/publicCatalogConstants';
export const Paths = {
  HOMEPAGE: '/',
  CATALOG_PAGE: `/courses`,
  CATALOG_PAGE_WITH_PAGINATION: `/courses/page/:pageNumber`,
  CATALOG_PAGE_WITH_PATH_FILTER: `/courses/:pathFilter(${pathFilters.join('|')})`,
  CATALOG_PAGE_WITH_PATH_FILTER_AND_PAGINATION: `/courses/:pathFilter(${pathFilters.join('|')})/page/:pageNumber`,
  CERTIFICATION_OVERVIEW: '/certification-overview',
  COURSE_LANDING: '/courses/:pagePath',
  LESSON_LANDING: '/lessons/:pagePath'
};