import { useCallback, useState } from 'react';
const useToggle = (initialState = false) => {
  const [isShowing, setState] = useState(initialState);
  const toggle = useCallback(nextState => setState(state => nextState || !state), []);
  const hide = useCallback(() => setState(false), []);
  const show = useCallback(() => setState(true), []);
  return {
    isShowing,
    toggle,
    show,
    hide
  };
};
export default useToggle;