import { useEffect, useMemo, useState } from 'react';
import debounce from 'hs-lodash/debounce';
const useDebouncedValue = (value, interval = 250) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedSetState = useMemo(() => debounce(setDebouncedValue, interval), [interval]);
  useEffect(() => {
    if (value !== debouncedValue) {
      debouncedSetState(value);
    }
  }, [debouncedSetState, debouncedValue, value]);
  return debouncedValue;
};
export default useDebouncedValue;