import { registerQuery, useQuery } from 'academy-common-lib/utils/DataFetchingClient';
import quickFetch from '../../utils/quickFetch';
import Raven from 'raven-js';
export const GET_PUBLIC_COURSE = registerQuery({
  fieldName: 'publicCourse',
  args: ['pagePath', 'language'],
  fetcher({
    pagePath,
    language
  }) {
    return quickFetch(`/academy-catalog-public/v1/tracks/${language}/${pagePath}`);
  }
});
export const usePublicCourse = (pagePath, language) => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_PUBLIC_COURSE, {
    variables: {
      pagePath,
      language
    },
    onError(e) {
      Raven.captureException(e);
    }
  });
  return {
    publicCourse: data,
    publicCourseLoading: loading,
    publicCourseError: error
  };
};