import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
const ReactRouterScrollToTop = () => {
  const {
    pathname
  } = useLocation();
  useLayoutEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [pathname]);
  return null;
};
export default ReactRouterScrollToTop;