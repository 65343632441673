import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
function getEnv() {
  return enviro.getShort('filemanager');
}
export const constructShard = (apexDomainSuffix, formattedShardIndex) => {
  const portalId = PortalIdParser.get();
  return `${portalId}.fs1.hubspotusercontent${apexDomainSuffix}${formattedShardIndex}.net`;
};
export const getShardDomainSuffixFromEnv = env => env === 'qa' ? 'qa' : '';
export function getCdnHostnameForNonDefaultHublet() {
  const shardApexDomainSuffix = getShardDomainSuffixFromEnv(getEnv());
  return constructShard(shardApexDomainSuffix, `-${enviro.getHublet()}`);
}
export const getCdnHostnameForPortal = () => {
  return getCdnHostnameForNonDefaultHublet();
};