import { useSearchParams } from '../../utils/searchParams';
import usageTracker from '../../utils/usageTracker';
import { FilterNames, mainFilters, dropdownFilters } from './publicCatalogConstants';
import { filters } from './publicCatalogDropdownConstants';
import difference from 'hs-lodash/difference';
import { getNewPagePathName, removePathFilterFromPathName } from './publicCatalogRouting';
import { useParams, useLocation } from 'react-router-dom';
export const useGetPathFilterApplied = () => {
  const pathParams = useParams();
  return pathParams.pathFilter || null;
};
const useSetSearchParamsAndResetPageNumber = () => {
  const [__, setSearchParams] = useSearchParams();
  const location = useLocation();
  const pathFilterApplied = useGetPathFilterApplied();
  return newSearchParams => {
    const isSettingAMainFilter = mainFilters.some(mainFilter => mainFilter in newSearchParams);
    const pathName = !!pathFilterApplied && isSettingAMainFilter // if we're setting a main filter and a path filter is currently applied, remove the path filter since the main filter takes precedence
    ? removePathFilterFromPathName(location.pathname) : location.pathname;
    const pageOnePathName = getNewPagePathName(pathName, 1); // set the page number to 1 when setting a new filter
    setSearchParams(newSearchParams, pageOnePathName);
  };
};
export const useClearFilter = () => {
  const setSearchParamsAndResetPageNumber = useSetSearchParamsAndResetPageNumber();
  return filterName => {
    setSearchParamsAndResetPageNumber({
      [filterName]: []
    });
    usageTracker.track('interaction', {
      action: 'clear-catalog-filter',
      value: filterName
    });
  };
};
export const useAddFilter = () => {
  const [searchParams] = useSearchParams();
  const setSearchParamsAndResetPageNumber = useSetSearchParamsAndResetPageNumber();
  return (filterName, value) => {
    setSearchParamsAndResetPageNumber({
      [filterName]: [...(searchParams[filterName] || []), value]
    });
    usageTracker.track('interaction', {
      action: 'add-catalog-filter',
      value: `${filterName}: ${value}`
    });
  };
};
export const useRemoveFilter = () => {
  const [searchParams] = useSearchParams();
  const setSearchParamsAndResetPageNumber = useSetSearchParamsAndResetPageNumber();
  return (filterName, toBeRemoved) => {
    setSearchParamsAndResetPageNumber({
      [filterName]: searchParams[filterName].filter(value => value !== toBeRemoved)
    });
    usageTracker.track('interaction', {
      action: 'remove-catalog-filter',
      value: `${filterName}: ${toBeRemoved}`
    });
  };
};
export const useSetFilter = () => {
  const setSearchParamsAndResetPageNumber = useSetSearchParamsAndResetPageNumber();
  return (filterName, value) => {
    setSearchParamsAndResetPageNumber({
      [filterName]: value
    });
    usageTracker.track('interaction', {
      action: 'add-catalog-filter',
      value: `${filterName}: ${value}`
    });
  };
};
export const useToggleFilter = () => {
  const [searchParams] = useSearchParams();
  const addFilter = useAddFilter();
  const removeFilter = useRemoveFilter();
  return (filterName, value) => {
    var _searchParams$filterN;
    if ((_searchParams$filterN = searchParams[filterName]) !== null && _searchParams$filterN !== void 0 && _searchParams$filterN.includes(value)) {
      removeFilter(filterName, value);
    } else {
      addFilter(filterName, value);
    }
  };
};
export const useClearAllFilters = () => {
  const setSearchParamsAndResetPageNumber = useSetSearchParamsAndResetPageNumber();
  const clearAllFilters = () => {
    const clearedFilters = Object.values(FilterNames).reduce((acc, filterName) => {
      acc[filterName] = [];
      return acc;
    }, {});
    setSearchParamsAndResetPageNumber(clearedFilters);
    usageTracker.track('interaction', {
      action: 'clear-all-catalog-filters'
    });
  };
  return clearAllFilters;
};
export const useSetMainFilter = () => {
  // setting one of the main filters (cat / tools / search) will reset the other two since only one can be active at a time
  const [searchParams] = useSearchParams();
  const setSearchParamsAndResetPageNumber = useSetSearchParamsAndResetPageNumber();
  return (filterName, filterValue) => {
    setSearchParamsAndResetPageNumber(Object.assign({}, searchParams, {
      tools: undefined,
      search: undefined,
      cat: undefined,
      [filterName]: filterValue
    }));
    usageTracker.track('interaction', {
      action: 'add-catalog-filter',
      value: `${filterName}: ${filterValue}`
    });
  };
};
export const useGetMainFilterApplied = () => {
  const [searchParams] = useSearchParams();
  // many main filters might be present in the URL at the same time, but only one can be active at a time so take the first one found
  return Object.keys(searchParams).findLast(filter =>
  // @ts-expect-error too strict typing
  mainFilters.includes(filter));
};
export const useGetDropdownFilterApplied = () => {
  const [searchParams] = useSearchParams();
  const mainFilterApplied = useGetMainFilterApplied();
  // @ts-expect-error includes is too strict
  if (!mainFilterApplied || !dropdownFilters.includes(mainFilterApplied)) return null;
  const dropdownFilterApplied = mainFilterApplied;
  const dropdownFilterValue = searchParams[dropdownFilterApplied][0];
  const filter = filters[dropdownFilterApplied];
  const subMenuOptions = [...filter.marketingOptions, ...filter.salesOptions, ...filter.serviceOptions];
  const topLevelOptions = difference(filter.allOptions, subMenuOptions);
  const isAppliedValueATopLevelOption =
  // @ts-expect-error includes is too strict
  topLevelOptions.includes(dropdownFilterValue);
  return {
    dropdownFilterApplied,
    dropdownFilterValue,
    isAppliedValueATopLevelOption
  };
};