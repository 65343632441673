import { useLocation, useHistory } from 'react-router-dom';
import { parse, stringify } from 'hub-http/helpers/params';
export const useSearchParams = () => {
  const history = useHistory();
  const location = useLocation();
  const allParams = parse(location.search.slice(1));
  // ensure all values are arrays
  Object.entries(allParams).forEach(([paramName, paramValues]) => {
    if (typeof paramValues === 'string') {
      allParams[paramName] = [paramValues];
    }
  });
  const setParams = (paramsToSet, newPathname) => {
    history.replace({
      pathname: newPathname || location.pathname,
      search: stringify(Object.assign({}, allParams, paramsToSet))
    });
  };
  return [allParams, setParams];
};