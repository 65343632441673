import { supportedPublicLanguages } from 'academy-common-lib/constants/academySupportedLanguages';
export const LEVEL_OPTIONS = ['ALL', 'BEGINNER', 'INTERMEDIATE', 'ADVANCED'];
export const DURATION_OPTIONS = ['ALL', 'THIRTY_MINUTES_OR_LESS', 'THIRTY_MINUTES_TO_ONE_HOUR', 'ONE_TO_TWO_HOURS', 'TWO_TO_THREE_HOURS', 'MORE_THAN_THREE_HOURS'];
export const CONTENT_TYPE_OPTIONS = ['ALL', 'COURSE', 'LESSON'];
export let FilterNames;
(function (FilterNames) {
  FilterNames["DURATION"] = "duration";
  FilterNames["LEVEL"] = "level";
  FilterNames["CONTENT_TYPE"] = "contentType";
  FilterNames["AWARD_TYPE"] = "awardType";
  FilterNames["LANGUAGE"] = "language";
})(FilterNames || (FilterNames = {}));
export const CERTIFICATIONS_ONLY_OPTION = 'ONLY_SHOW_CERTIFICATE_COURSES';
export const AWARD_TYPE_OPTIONS = ['ALL', CERTIFICATIONS_ONLY_OPTION];
export const PublicCatalogFilters = {
  [FilterNames.AWARD_TYPE]: AWARD_TYPE_OPTIONS,
  [FilterNames.LEVEL]: LEVEL_OPTIONS,
  [FilterNames.DURATION]: DURATION_OPTIONS,
  [FilterNames.CONTENT_TYPE]: CONTENT_TYPE_OPTIONS,
  [FilterNames.LANGUAGE]: supportedPublicLanguages
};
const FilterOptions = Object.values(PublicCatalogFilters);
export const dropdownFilters = ['cat', 'tools'];
export const mainFilters = ['search', ...dropdownFilters];
export const pathFilters = ['marketing', 'sales', 'service', 'software'];