import { categories, marketingCategories, salesCategories, serviceCategories, tools, marketingHubTools, salesHubTools, serviceHubTools } from 'academy-common-lib/constants/categories';
export const filters = {
  cat: {
    allOptions: categories,
    marketingOptions: marketingCategories,
    salesOptions: salesCategories,
    serviceOptions: serviceCategories,
    marketingSubMenuName: 'MARKETING',
    salesSubMenuName: 'SALES',
    serviceSubMenuName: 'SERVICE',
    apiKey: 'subCategories'
  },
  tools: {
    allOptions: tools,
    marketingOptions: marketingHubTools,
    salesOptions: salesHubTools,
    serviceOptions: serviceHubTools,
    marketingSubMenuName: 'MARKETING_HUB_TOOLS',
    salesSubMenuName: 'SALES_HUB_TOOLS',
    serviceSubMenuName: 'SERVICE_HUB_TOOLS',
    apiKey: 'hsSoftwareFilters'
  }
};
export const subMenuNamesAndOptions = {
  cat: {
    [filters.cat.marketingSubMenuName]: filters.cat.marketingOptions,
    [filters.cat.salesSubMenuName]: filters.cat.salesOptions,
    [filters.cat.serviceSubMenuName]: filters.cat.serviceOptions
  },
  tools: {
    [filters.tools.marketingSubMenuName]: filters.tools.marketingOptions,
    [filters.tools.salesSubMenuName]: filters.tools.salesOptions,
    [filters.tools.serviceSubMenuName]: filters.tools.serviceOptions
  }
};
export const categorySubMenus = Object.keys(subMenuNamesAndOptions.cat);
export const toolsSubMenus = Object.keys(subMenuNamesAndOptions.tools);
export const subMenus = [...categorySubMenus, ...toolsSubMenus];