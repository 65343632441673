import { useEffect, useCallback } from 'react';
import { getProxyUrl } from 'academy-common-lib/utils/url';
import enviro from 'enviro';
import Raven from 'raven-js';
import I18n from 'I18n';
const getSignupParams = type => {
  const params = {
    ['LESSON']: {
      learningType: 'lesson',
      slug: 'lessons',
      landingPageType: 'lesson'
    },
    ['TRACK']: {
      learningType: 'track',
      slug: 'courses',
      landingPageType: 'course'
    },
    ['CERTIFICATION']: {
      learningType: 'track',
      slug: 'courses',
      landingPageType: 'course'
    }
  };
  return params[type];
};
export default ((pagePath, id, type, language, attachTo, disableFocus = false) => {
  const handleSetupEmbedder = useCallback(() => {
    const baseUrl = getProxyUrl();
    const baseUrlWithoutProtocol = baseUrl.split('//')[1];
    const {
      slug,
      learningType,
      landingPageType
    } = getSignupParams(type);
    const _hsq = window._hsq || [];
    const signUpTitle = I18n.text('academyPublic.signupTitle');
    const SignupEmbedder = window.SignupEmbedder;
    try {
      window.embedded = new SignupEmbedder().setEnvironment(enviro.isQa() ? 1 : 2).setLanguage(language).setFlow('academy').setOptions(new window.SignupEmbedderOptions().setAutofocusDisabled(disableFocus).setCarryParameters(true).setTitle(signUpTitle)).addQueryParam({
        landingPageType,
        'hubs_signup-url': `${baseUrlWithoutProtocol}/${slug}/${pagePath}`.replace(/\/\/+/, '/'),
        intent: 'learningCenter',
        [learningType]: id,
        lang: language
      }).attachTo(attachTo);
      _hsq.push(['addPrivacyConsentListener', consent => {
        if (consent.allowed) {
          window.embedded.postCookieConsentMessage({
            allowed: true
          });
        }
      }]);
    } catch (error) {
      Raven.captureException(error);
    }
  }, [attachTo, disableFocus, id, language, pagePath, type]);
  useEffect(() => {
    const script = document.createElement('script');
    script.onload = handleSetupEmbedder;
    script.async = true;
    script.defer = true;
    script.src = 'https://js.hubspot.com/signup-ui-lego-embedder/embedder.js';
    document.body.appendChild(script);
  }, [handleSetupEmbedder]);
});