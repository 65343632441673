import { usePublicCatalogResults } from '../publicCatalog';
import { getPublicLang } from 'academy-common-lib/utils/url';
export const useGetPopularCoursesForHomepage = () => {
  const {
    publicCatalogData,
    publicCatalogError,
    publicCatalogLoading
  } = usePublicCatalogResults({
    contentTypes: ['TRACK'],
    pageLength: 12,
    offset: {
      cursor: 0
    },
    featuredHomepageOnly: true,
    language: getPublicLang() || 'en'
  });
  return {
    popularCoursesData: publicCatalogData,
    popularCoursesError: publicCatalogError,
    popularCoursesLoading: publicCatalogLoading
  };
};