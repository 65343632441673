import { registerQuery, useQuery } from 'academy-common-lib/utils/DataFetchingClient';
import quickFetch from '../../utils/quickFetch';
const GET_COOKIE_ECHO = registerQuery({
  fieldName: 'cookieEcho',
  args: [],
  fetcher() {
    return quickFetch('/cookie-echo/v1/user');
  }
});
export const useIsLoggedIn = () => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_COOKIE_ECHO);
  return {
    isLoggedIn: !!data,
    isLoggedInLoading: loading,
    isLoggedInError: error
  };
};