import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["error", "data"],
  _excluded2 = ["data", "error"];
import { DataFetchingClient, DataFetchingClientProvider, useQuery as useQueryBase, useMutation as useMutationBase, registerQuery as registerQueryBase } from 'data-fetching-client';
const StatusCode = {
  NO_CONNECTION: 0,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504
};
export const unwrapHubHttpError = error => {
  var _error$graphQLErrors;
  if (error && error.networkError) {
    return error.networkError;
  }

  // to be reviewed with the CHIRP team
  if (error && (_error$graphQLErrors = error.graphQLErrors) !== null && _error$graphQLErrors !== void 0 && _error$graphQLErrors.length) {
    var _error$graphQLErrors$;
    const code = (_error$graphQLErrors$ = error.graphQLErrors[0].extensions) === null || _error$graphQLErrors$ === void 0 ? void 0 : _error$graphQLErrors$.code;
    if (code && StatusCode[code]) {
      return {
        status: StatusCode[code]
      };
    }
  }
  return undefined;
};
const unwrapData = data => {
  if (!data) return null;
  const key = Object.keys(data)[0];
  return data[key];
};
export * from 'data-fetching-client';
export class AcademyFetchingClient extends DataFetchingClient {
  queryUnwrapped(opts) {
    return this.query(opts).then(result => {
      const key = opts.query.definitions[0].name.value;
      const data = result.data;
      return data[key];
    }, error => {
      throw unwrapHubHttpError(error);
    });
  }
}
export const useQuery = (query, options) => {
  const _useQueryBase = useQueryBase(query, options),
    {
      error,
      data
    } = _useQueryBase,
    rest = _objectWithoutPropertiesLoose(_useQueryBase, _excluded);
  return Object.assign({}, rest, {
    data: unwrapData(data),
    error: unwrapHubHttpError(error)
  });
};
export const useMutation = (query, options) => {
  const [mutate, _ref] = useMutationBase(query, options);
  const {
      data,
      error
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded2);
  return [mutate, Object.assign({}, rest, {
    data: unwrapData(data),
    error: unwrapHubHttpError(error)
  })];
};
export const registerQuery = options => {
  return registerQueryBase({
    fieldName: options.fieldName,
    args: options.args,
    fetcher(variables, ctx) {
      return options.fetcher(variables, ctx);
    }
  });
};
export { DataFetchingClientProvider };