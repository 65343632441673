import { getFetch } from './global';
const fetch = getFetch();
async function fetchBenderProxyConfig() {
  if (typeof fetch !== 'undefined' && typeof fetch === 'function') {
    try {
      const resp = await fetch('https://localhost/bender-proxy/config', {
        // internal app, nobody is using safari 11
        // eslint-disable-next-line compat/compat
        signal: AbortSignal.timeout(1000)
      });
      return await resp.json();
    } catch (error) {
      console.error('Error fetching bender proxy config:', error);
      return Promise.resolve({
        applied: []
      });
    }
  }
  return Promise.resolve({
    applied: []
  });
}
export async function describeLocalChirpServer(appRoot) {
  const url = `https://localhost${appRoot}/_chirp/_describe`;
  if (typeof fetch !== 'undefined' && typeof fetch === 'function') {
    try {
      const resp = await fetch(url, {
        signal: AbortSignal.timeout(1000)
      });
      if (resp.ok) {
        return await resp.json();
      } else {
        // Not a chirp service
        return Promise.resolve({
          serviceNames: [],
          authType: 'internal'
        });
      }
    } catch (error) {
      console.error('Error fetching local chirp server:', error);
      return Promise.resolve({
        serviceNames: [],
        authType: 'internal'
      });
    }
  }
  return Promise.resolve({
    serviceNames: [],
    authType: 'internal'
  });
}
export async function fetchLocalServices() {
  const {
    applied
  } = await fetchBenderProxyConfig();
  const dwConfigs = applied.filter(conf => conf.config.path[0] === 'dropwizard');
  const localChirpServicePromises = dwConfigs.map(async conf => {
    const appRoot = conf.config.routes[0].appRoot;
    const {
      serviceNames
    } = await describeLocalChirpServer(appRoot);
    return serviceNames.map(name => ({
      name,
      appRoot
    }));
  });
  const results = await Promise.allSettled(localChirpServicePromises);
  return results.filter(result => result.status === 'fulfilled').flatMap(result => result.value);
}