export const categories = ['MARKETING', 'SALES', 'SERVICE', 'CONTACT_MANAGEMENT', 'CONTENT_MARKETING', 'EMAIL_MARKETING', 'LEAD_GENERATION', 'AUTOMATION', 'SEO', 'SOCIAL_MEDIA', 'WEBSITE_DESIGN', 'CONTACT_MANAGEMENT_SALES', 'INBOUND_SALES', 'SALES_ENABLEMENT', 'CONTACT_MANAGEMENT_SERVICE', 'OPERATIONS', 'ARTIFICIAL_INTELLIGENCE', 'WEB_DESIGN', 'WEB_DEVELOPMENT', 'DATA_PRIVACY', 'INBOUND_MARKETING', 'REPORTING_AND_PERFORMANCE', 'INBOUND_SERVICE'];
export const marketingCategories = ['INBOUND_MARKETING', 'CONTACT_MANAGEMENT', 'CONTENT_MARKETING', 'EMAIL_MARKETING', 'LEAD_GENERATION', 'AUTOMATION', 'SEO', 'SOCIAL_MEDIA', 'WEBSITE_DESIGN', 'REPORTING_AND_PERFORMANCE'];
export const salesCategories = ['CONTACT_MANAGEMENT_SALES', 'INBOUND_SALES', 'SALES_ENABLEMENT'];
export const serviceCategories = ['CONTACT_MANAGEMENT_SERVICE', 'INBOUND_SERVICE'];
export const webCategories = ['WEB_DESIGN', 'WEB_DEVELOPMENT'];
export const tools = ['MARKETING_HUB_TOOLS', 'SALES_HUB_TOOLS', 'SERVICE_HUB_TOOLS', 'ADS', 'BLOGS', 'CAMPAIGNS', 'MARKETING_CONTACTS', 'MARKETING_CRM', 'CTA', 'DESIGN_MANAGER', 'EMAIL', 'FORMS', 'LANDING_PAGE', 'LISTS', 'MARKETING_REPORTS', 'SEO', 'SOCIAL', 'TEMPLATES', 'WEBSITE', 'WORKFLOWS', 'SALES_CONTACTS', 'SALES_CRM', 'DEALS', 'DOCUMENTS', 'MEETINGS', 'SALES_REPORTS', 'SEQUENCES', 'SALES_SNIPPETS', 'TASKS', 'SERVICE_HUB_CONTACTS', 'SERVICE_HUB_CRM', 'CUSTOMER_FEEDBACK', 'KNOWLEDGE_BASE', 'SERVICE_HUB_REPORTS', 'TICKETS', 'HUBSPOT_CMS', 'DEVELOPER_TOOLS', 'INTEGRATIONS', 'ACCOUNT_SETUP', 'AUTOMATION', 'PROSPECTING', 'BREEZE_AI'];
export const marketingHubTools = ['ADS', 'BLOGS', 'CAMPAIGNS', 'MARKETING_CONTACTS', 'MARKETING_CRM', 'CTA', 'DESIGN_MANAGER', 'EMAIL', 'FORMS', 'LANDING_PAGE', 'LISTS', 'MARKETING_REPORTS', 'SEO', 'SOCIAL', 'TEMPLATES', 'WEBSITE', 'WORKFLOWS'];
export const salesHubTools = ['SALES_CONTACTS', 'SALES_CRM', 'DEALS', 'DOCUMENTS', 'MEETINGS', 'SALES_REPORTS', 'SEQUENCES', 'SALES_SNIPPETS', 'TASKS', 'PROSPECTING'];
export const serviceHubTools = ['SERVICE_HUB_CONTACTS', 'SERVICE_HUB_CRM', 'CUSTOMER_FEEDBACK', 'KNOWLEDGE_BASE', 'SERVICE_HUB_REPORTS', 'TICKETS', 'AUTOMATION'];
export const categoriesI18nKeys = {
  MARKETING: 'academyCommon.categories.MARKETING',
  SALES: 'academyCommon.categories.SALES',
  SERVICE: 'academyCommon.categories.SERVICE',
  CONTACT_MANAGEMENT: 'academyCommon.categories.CONTACT_MANAGEMENT',
  CONTENT_MARKETING: 'academyCommon.categories.CONTENT_MARKETING',
  EMAIL_MARKETING: 'academyCommon.categories.EMAIL_MARKETING',
  LEAD_GENERATION: 'academyCommon.categories.LEAD_GENERATION',
  AUTOMATION: 'academyCommon.categories.AUTOMATION',
  SEO: 'academyCommon.categories.SEO',
  SOCIAL_MEDIA: 'academyCommon.categories.SOCIAL_MEDIA',
  WEBSITE_DESIGN: 'academyCommon.categories.WEBSITE_DESIGN',
  CONTACT_MANAGEMENT_SALES: 'academyCommon.categories.CONTACT_MANAGEMENT_SALES',
  INBOUND_SALES: 'academyCommon.categories.INBOUND_SALES',
  SALES_ENABLEMENT: 'academyCommon.categories.SALES_ENABLEMENT',
  CONTACT_MANAGEMENT_SERVICE: 'academyCommon.categories.CONTACT_MANAGEMENT_SERVICE',
  OPERATIONS: 'academyCommon.categories.OPERATIONS',
  ARTIFICIAL_INTELLIGENCE: 'academyCommon.categories.ARTIFICIAL_INTELLIGENCE',
  WEB_DESIGN: 'academyCommon.categories.WEB_DESIGN',
  WEB_DEVELOPMENT: 'academyCommon.categories.WEB_DEVELOPMENT',
  DATA_PRIVACY: 'academyCommon.categories.DATA_PRIVACY',
  INBOUND_MARKETING: 'academyCommon.categories.INBOUND_MARKETING',
  REPORTING_AND_PERFORMANCE: 'academyCommon.categories.REPORTING_AND_PERFORMANCE',
  INBOUND_SERVICE: 'academyCommon.categories.INBOUND_SERVICE'
};
export const toolsI18nKeys = {
  MARKETING_HUB_TOOLS: 'academyCommon.tools.MARKETING_HUB_TOOLS',
  SALES_HUB_TOOLS: 'academyCommon.tools.SALES_HUB_TOOLS',
  SERVICE_HUB_TOOLS: 'academyCommon.tools.SERVICE_HUB_TOOLS',
  ADS: 'academyCommon.tools.ADS',
  BLOGS: 'academyCommon.tools.BLOGS',
  CAMPAIGNS: 'academyCommon.tools.CAMPAIGNS',
  MARKETING_CONTACTS: 'academyCommon.tools.MARKETING_CONTACTS',
  MARKETING_CRM: 'academyCommon.tools.MARKETING_CRM',
  CTA: 'academyCommon.tools.CTA',
  DESIGN_MANAGER: 'academyCommon.tools.DESIGN_MANAGER',
  EMAIL: 'academyCommon.tools.EMAIL',
  FORMS: 'academyCommon.tools.FORMS',
  LANDING_PAGE: 'academyCommon.tools.LANDING_PAGE',
  LISTS: 'academyCommon.tools.LISTS',
  MARKETING_REPORTS: 'academyCommon.tools.MARKETING_REPORTS',
  SEO: 'academyCommon.tools.SEO',
  SOCIAL: 'academyCommon.tools.SOCIAL',
  TEMPLATES: 'academyCommon.tools.TEMPLATES',
  WEBSITE: 'academyCommon.tools.WEBSITE',
  WORKFLOWS: 'academyCommon.tools.WORKFLOWS',
  SALES_CONTACTS: 'academyCommon.tools.SALES_CONTACTS',
  SALES_CRM: 'academyCommon.tools.SALES_CRM',
  DEALS: 'academyCommon.tools.DEALS',
  DOCUMENTS: 'academyCommon.tools.DOCUMENTS',
  MEETINGS: 'academyCommon.tools.MEETINGS',
  SALES_REPORTS: 'academyCommon.tools.SALES_REPORTS',
  SEQUENCES: 'academyCommon.tools.SEQUENCES',
  SALES_SNIPPETS: 'academyCommon.tools.SALES_SNIPPETS',
  TASKS: 'academyCommon.tools.TASKS',
  SERVICE_HUB_CONTACTS: 'academyCommon.tools.SERVICE_HUB_CONTACTS',
  SERVICE_HUB_CRM: 'academyCommon.tools.SERVICE_HUB_CRM',
  CUSTOMER_FEEDBACK: 'academyCommon.tools.CUSTOMER_FEEDBACK',
  KNOWLEDGE_BASE: 'academyCommon.tools.KNOWLEDGE_BASE',
  SERVICE_HUB_REPORTS: 'academyCommon.tools.SERVICE_HUB_REPORTS',
  TICKETS: 'academyCommon.tools.TICKETS',
  HUBSPOT_CMS: 'academyCommon.tools.HUBSPOT_CMS',
  DEVELOPER_TOOLS: 'academyCommon.tools.DEVELOPER_TOOLS',
  INTEGRATIONS: 'academyCommon.tools.INTEGRATIONS',
  ACCOUNT_SETUP: 'academyCommon.tools.ACCOUNT_SETUP',
  AUTOMATION: 'academyCommon.tools.AUTOMATION',
  PROSPECTING: 'academyCommon.tools.PROSPECTING',
  BREEZE_AI: 'academyCommon.tools.BREEZE_AI'
};