export const getShareLinks = (link, template = '') => {
  const encodedLink = link && encodeURIComponent(link);
  return {
    linkForX: `https://twitter.com/share?url=${encodedLink}&text=${template}`,
    linkForFacebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`,
    linkForLinkedIn: `https://www.linkedin.com/feed/?shareActive=true&text=${template}${encodedLink}`,
    linkForWhatsapp: `https://wa.me/?text=${template}${encodedLink}`
  };
};
export const PROXY_URL_LANGUAGE_VARIANTS = {
  en: 'academy.hubspot.com',
  es: 'academy.hubspot.com/es',
  pt: 'academy.hubspot.com/pt',
  de: 'academy.hubspot.de',
  fr: 'academy.hubspot.fr',
  ja: 'academy.hubspot.jp'
};
const PROXY_URL_REGEX = /academy\.hubspot\.(com\/\w{2}\/|\w{2,3}\/)/;
export const isProxyUrl = (href = window.location.href) => {
  return PROXY_URL_REGEX.test(href);
};
export const getPublicUrlLanguageVariant = (url, language) => {
  if (isProxyUrl(url)) {
    return url.replace(PROXY_URL_REGEX, `${PROXY_URL_LANGUAGE_VARIANTS[language]}/`);
  }
  return url.replace(/academy\/public\/?(\w{2}\/)?/g, language === 'en' ? 'academy/public/' : `academy/public/${language}/`);
};
const URL_LANG = /.*\/([a-z]{2})\/.*/;
export const getPublicLang = (href = window.location.href) => {
  if (href.includes('academy.hubspot.de')) {
    return 'de';
  }
  if (href.includes('academy.hubspot.com/es')) {
    return 'es';
  }
  if (href.includes('academy.hubspot.fr')) {
    return 'fr';
  }
  if (href.includes('academy.hubspot.com/pt')) {
    return 'pt';
  }
  if (href.includes('academy.hubspot.jp')) {
    return 'ja';
  }
  const [__, locale] = href.match(URL_LANG) || [null, 'en'];
  return locale;
};
export const isPrerenderAgent = () => {
  return window.navigator.userAgent.includes('Prerender') || window.navigator.userAgent.includes('prerender');
};

/**
 * Used to get the public url relative to the current domain
 */
export const getProxyUrl = (path = '', language = getPublicLang()) => {
  const cleanPath = path.replace(/^\//, '').replace(/\/$/, '');
  return `https://${PROXY_URL_LANGUAGE_VARIANTS[language]}/${cleanPath}`;
};
export const getOverviewModalParams = ({
  id,
  type,
  language,
  source
}) => {
  return Object.assign({
    overviewType: type,
    overviewEntityId: id.toString()
  }, language && {
    overviewLanguage: language
  }, source && {
    overviewSource: source
  });
};