import { registerQuery, useQuery } from 'academy-common-lib/utils/DataFetchingClient';
import noAuthHttp from 'hub-http/clients/noAuthApiClient';
import { SEARCH_QUERY } from '../../../__generated__/chirp/com/hubspot/learning/center/search/no/auth/rpc/PublicSearchService.dfc';
import { getPublicLang } from 'academy-common-lib/utils/url';
const FILTERS_API = '/api/academy-catalog-public/v1/filters/all';
export const usePublicCatalogResults = ({
  search,
  categories,
  offset,
  pageLength,
  hubSpotSoftware,
  levels,
  durations,
  contentTypes = ['TRACK', 'LESSON'],
  language,
  hasAwardsOnly,
  featuredHomepageOnly,
  featuredCertificationsOnly
}, options) => {
  const upperCaseLang = language && language.toUpperCase() || getPublicLang().toUpperCase();
  const {
    data: searchData,
    loading: searchLoading,
    error: searchError
  } = useQuery(SEARCH_QUERY, {
    variables: {
      search,
      categories: categories || [],
      levels: levels || [],
      hubSpotSoftware: hubSpotSoftware || [],
      durations: durations || [],
      contentTypes: contentTypes || [],
      language: upperCaseLang,
      featuredHomepageOnly,
      featuredCertificationsOnly,
      hasAwardsOnly,
      offset: offset || {
        cursor: 0
      },
      pageLength,
      shouldFilterDeprecated: true
    },
    skip: options === null || options === void 0 ? void 0 : options.skip
  });
  return {
    publicCatalogData: searchData,
    publicCatalogLoading: searchLoading,
    publicCatalogError: searchError
  };
};
export const FILTERS_QUERY = registerQuery({
  args: [],
  fieldName: 'filters',
  fetcher: () => {
    return noAuthHttp.get(FILTERS_API);
  }
});
export const usePublicCatalogFilters = () => {
  const {
    data: filtersData,
    loading: filtersLoading,
    error: filtersError
  } = useQuery(FILTERS_QUERY);
  return {
    filtersData: filtersData,
    filtersLoading,
    filtersError
  };
};