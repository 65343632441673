import { usePublicCatalogResults } from './publicCatalogQueries';
import { useGetMainFilterApplied, useGetPathFilterApplied } from './publicCatalogFiltersHooks';
import { useSearchParams } from '../../utils/searchParams';
import { useGetPageNumber } from './publicCatalogPaginationHooks';
import difference from 'hs-lodash/difference';
import { subMenuNamesAndOptions } from './publicCatalogDropdownConstants';
import { tools } from 'academy-common-lib/constants/categories';
import { getPublicLang } from 'academy-common-lib/utils/url';
const CONTENT_TYPES_ALLOWED_ON_ACADEMY_PUBLIC_CATALOG = ['TRACK', 'LESSON'];
const getCategoriesAndToolsFilterValues = ({
  pathFilterApplied,
  mainFilterApplied,
  searchParams
}) => {
  // Main filter takes precedence over path filter
  if (mainFilterApplied === 'cat') {
    const categories =
    // @ts-expect-error if category is a subMenuName then we include all subCategories in the request, else we default to the searchParams value
    subMenuNamesAndOptions.cat[searchParams.cat[0]] || searchParams.cat;
    return {
      categories,
      hubSpotSoftware: []
    };
  }
  if (mainFilterApplied === 'tools') {
    const hubSpotSoftware =
    // @ts-expect-error if tools is a subMenuName then we include all subCategories in the request, else we default to the searchParams value
    subMenuNamesAndOptions.tools[searchParams.tools[0]] || searchParams.tools;
    return {
      categories: [],
      hubSpotSoftware
    };
  }
  if (pathFilterApplied === 'software') {
    const hubSpotSoftware = difference(tools,
    // all options including subMenuNames
    Object.keys(subMenuNamesAndOptions.tools) // subMenuNames
    ); // all options excluding subMenuNames
    return {
      categories: [],
      hubSpotSoftware
    };
  }
  if (pathFilterApplied && Object.keys(subMenuNamesAndOptions.cat).includes(pathFilterApplied.toUpperCase())) {
    const categories =
    // @ts-expect-error we've already checked that subMenuNamesAndOptions.cat has the pathFilterApplied key
    subMenuNamesAndOptions.cat[pathFilterApplied.toUpperCase()];
    return {
      categories,
      hubSpotSoftware: []
    };
  }
  return {
    categories: [],
    hubSpotSoftware: []
  };
};
export const useGetCatalogResultsFromUrlFilters = ({
  pageSize,
  skip = false
}) => {
  var _searchParams$languag, _searchParams$content, _searchParams$awardTy;
  const [searchParams] = useSearchParams();
  const mainFilterApplied = useGetMainFilterApplied();
  const pathFilterApplied = useGetPathFilterApplied();
  const pageNumber = useGetPageNumber();
  const offset = (pageNumber - 1) * pageSize;
  const language = searchParams.language ? (_searchParams$languag = searchParams.language[0]) === null || _searchParams$languag === void 0 ? void 0 : _searchParams$languag.toLowerCase() : getPublicLang();
  const {
    categories,
    hubSpotSoftware
  } = getCategoriesAndToolsFilterValues({
    pathFilterApplied,
    mainFilterApplied,
    searchParams
  });
  const {
    publicCatalogData,
    publicCatalogError,
    publicCatalogLoading
  } = usePublicCatalogResults({
    search: mainFilterApplied === 'search' ? searchParams.search[0] : '',
    categories,
    hubSpotSoftware,
    levels: searchParams.level,
    durations: searchParams.duration,
    contentTypes: !((_searchParams$content = searchParams.contentType) !== null && _searchParams$content !== void 0 && _searchParams$content.length) ? CONTENT_TYPES_ALLOWED_ON_ACADEMY_PUBLIC_CATALOG : searchParams.contentType.map(type => type === 'COURSE' ? 'TRACK' : type),
    language: language.toUpperCase(),
    hasAwardsOnly: (_searchParams$awardTy = searchParams.awardType) === null || _searchParams$awardTy === void 0 ? void 0 : _searchParams$awardTy.includes('ONLY_SHOW_CERTIFICATE_COURSES'),
    pageLength: pageSize,
    offset: {
      cursor: offset
    },
    featuredHomepageOnly: false,
    featuredCertificationsOnly: false
  }, {
    skip
  });
  return {
    publicCatalogData,
    publicCatalogError,
    publicCatalogLoading,
    language
  };
};