import { PROXY_URL_LANGUAGE_VARIANTS, getPublicLang, isProxyUrl } from 'academy-common-lib/utils/url';
import { getFullUrl } from 'hubspot-url-utils';
import usageTracker from './usageTracker';
/**
 * Be mindful of what is imported into this file, as some modules may break
 * the publicEarlyRequester. The functions in this file were migrated from
 * utils.ts where some of the imports were throwing errors in the early
 * requester.
 */
export const ACADEMY_SIGNUP_URL = `${getFullUrl('app')}/signup-hubspot/academy`;
export const getPublicBasename = (href = window.location.href) => {
  if (href.includes('icebanu-8326197.hs-sites.com')) return '/'; // For initial CMS proxy testing
  if (href.includes(PROXY_URL_LANGUAGE_VARIANTS.es)) {
    return '/es/';
  }
  if (href.includes(PROXY_URL_LANGUAGE_VARIANTS.pt)) {
    return '/pt/';
  }
  if (href.includes(PROXY_URL_LANGUAGE_VARIANTS.de)) {
    return '/';
  }
  if (href.includes(PROXY_URL_LANGUAGE_VARIANTS.fr)) {
    return '/';
  }
  if (href.includes(PROXY_URL_LANGUAGE_VARIANTS.ja)) {
    return '/';
  }
  if (href.includes(PROXY_URL_LANGUAGE_VARIANTS.en)) {
    return '/';
  }
  const matchBasename = href.match(/\/academy\/public\/(\w{2}\/)?/) || [];
  return matchBasename[0] || '/academy/public/';
};
export const isPublicUrl = (href = window.location.href) => {
  return isProxyUrl(href) || href.includes('/academy/public/');
};
export const getSignupUrl = (contentType, id) => {
  const {
    hostname,
    pathname,
    search
  } = window.location;
  const params = new URLSearchParams(search);
  params.set('hubs_signup-url', hostname + pathname);
  params.set('lang', getPublicLang());
  if (contentType === 'COURSE') {
    params.set('intent', 'academyTrack');
    params.set('track', id.toString());
  } else {
    params.set('intent', 'academyLesson');
    params.set('lesson', id.toString());
  }
  return `${ACADEMY_SIGNUP_URL}?${params.toString()}`;
};
export const getSignupCtaHref = hubsSignupCtaName => {
  return `${ACADEMY_SIGNUP_URL}?hubs_signup-cta=${hubsSignupCtaName}&hubs_signup-url=${encodeURIComponent(window.location.hostname + window.location.pathname)}&lang=${getPublicLang()}`;
};
export const appendHubsContent = (href, ctaText) => {
  const hubsContent = encodeURIComponent(window.location.hostname + window.location.pathname);
  const delimiter = href.includes('?') ? '&' : '?';
  return `${href}${delimiter}hubs_content=${hubsContent}&hubs_content-cta=${encodeURIComponent(ctaText)}`;
};
export const trackFooterLinkClick = href => {
  usageTracker.track('navInteraction', {
    action: 'footer-link-click',
    href
  });
};