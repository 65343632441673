import { gate } from 'hub-http/gates';
import keyMirror from 'react-utils/keyMirror';
import Immutable from 'immutable';
export const TRACK_EVENT = '@@usage';
export const NOTIFICATION_EVENT = 'notification';
export const FREE_FILE_HOSTING_DOMAIN_GATE = 'free-file-hosting-domain';
export const PICKER_FIREALARM_GATE = 'FileManagerUI:PickerFireAlarm';
export const LARGE_FILE_UPLOADS_GATE = gate('FileManagerUI:LargeFileUploads');
export const PICKER_LARGE_FILE_UPLOADS_GATE = gate('FilePicker:LargeFileUploads');
export const IMAGE_GEN_V2_GATE = gate('FileManagerUI:imageGenV2');
export const VIDEO_SUBTITLE_GENERATION = gate('MediaContent:Video:SubtitleGeneration');
export const VIDEO_DETAILS_REFRESH_GATE = gate('MediaContent:Video:DetailsRefresh');
export const VIDEO_ADVANCED_FEATURES_ON_DEMAND_GATE = gate('MediaContent:Video:AdvancedFeaturesOnDemand');
export const SOURCE_GROUPS_GATE = gate('FileManagerUI:SourceGroups');
export const FILE_DEDUPLICATION_GATE = gate('FileManagerUI:FileDeduplication');
export const PLG_ZERO_STATE_GATE = gate('FileManagerUI:PLGZeroState');
export const CANVA_INTEGRATION_SCOPE = 'canva-integration';
export const MARKETING_VIDEO_SCOPE = 'marketing-video';
export const FILE_MANAGER_ACCESS = 'file-manager-access';
export const FILE_MANAGER_DASH_SCOPE = 'file-manager-dashboard-ui-access';
export const FILE_MANAGER_WRITE_SCOPE = 'file-manager-write';
export const TEAMS_ACCESS_SCOPE = 'teams-access';
export const FILE_MANAGER_ACCOUNT_VERIFICATION_SOURCE = 'FILE_MANAGER';
export const FILE_HOSTING_PAID_DOMAINS_SCOPE = 'file-hosting-paid-domains-access';
export const SUPER_ADMIN_SCOPE = 'super-admin';
export const FILE_MANAGER_DELETE_SCOPE = 'file-manager-delete';
export const IMAGE_GENERATION_SCOPE = 'image-generation-access';
export const FILE_MANAGER_VIEW_SCOPE = 'file-manager-view';
export const REMIX_WRITE_SCOPE = 'content-remix-write';
export let RequestStatus;
(function (RequestStatus) {
  RequestStatus["UNINITIALIZED"] = "UNINITIALIZED";
  RequestStatus["PENDING"] = "PENDING";
  RequestStatus["SUCCEEDED"] = "SUCCEEDED";
  RequestStatus["FAILED"] = "FAILED";
  RequestStatus["PRECONDITION_FAILED"] = "PRECONDITION_FAILED";
  RequestStatus["NOTFOUND"] = "NOTFOUND";
})(RequestStatus || (RequestStatus = {}));
export const COMPLETED_REQUEST_STATUSES = [RequestStatus.SUCCEEDED, RequestStatus.FAILED, RequestStatus.NOTFOUND];
export let FileTypes;
(function (FileTypes) {
  FileTypes["IMG"] = "IMG";
  FileTypes["MOVIE"] = "MOVIE";
  FileTypes["DOCUMENT"] = "DOCUMENT";
  FileTypes["AUDIO"] = "AUDIO";
  FileTypes["OTHER"] = "OTHER";
})(FileTypes || (FileTypes = {}));
export let ObjectCategory;
(function (ObjectCategory) {
  ObjectCategory["FILE"] = "FILE";
  ObjectCategory["FOLDER"] = "FOLDER";
})(ObjectCategory || (ObjectCategory = {}));
const IMG_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'ico', 'bmp', 'webp', 'svg'];
const MOVIE_TYPES = ['mov', 'avi', 'flv', 'wmv', 'rm', '3gp', '3g2', 'asf', 'asx', 'mpg', 'mp4', 'mpeg', 'swf', 'm4v', 'webm'];
const DOCUMENT_TYPES = ['csv', 'doc', 'docx', 'dot', 'dotx', 'key', 'md', 'pdf', 'pot', 'potx', 'pps', 'ppsx', 'ppt', 'pptx', 'txt', 'wpd', 'wps', 'wri', 'xls', 'xlsb', 'xlsx', 'xlt', 'xlx', 'xml'];
const AUDIO_TYPES = ['aac', 'aif', 'm4a', 'mp3', 'mpa', 'ra', 'wav', 'wma'];
export const SUBTITLE_TYPES = ['text/vtt'];
export const TypeToExtensions = Immutable.Map({
  [FileTypes.IMG]: Immutable.Set(IMG_TYPES),
  [FileTypes.MOVIE]: Immutable.Set(MOVIE_TYPES),
  [FileTypes.DOCUMENT]: Immutable.Set(DOCUMENT_TYPES),
  [FileTypes.AUDIO]: Immutable.Set(AUDIO_TYPES)
});
export const SystemRecognizedFileExtensionList = TypeToExtensions.flatten().toList();
export const FileSource = {
  AI_GENERATED: 'AI_GENERATED',
  CANVA: 'CANVA',
  SHUTTERSTOCK: 'SHUTTERSTOCK'
};
export const ContentTypes = {
  UNMAPPED: 'UNMAPPED',
  LANDING_PAGE: 'LANDING_PAGE',
  EMAIL: 'EMAIL',
  BLOG_POST: 'BLOG_POST',
  SITE_PAGE: 'SITE_PAGE',
  LEGACY_PAGE: 'LEGACY_PAGE',
  KNOWLEDGE_ARTICLE: 'KNOWLEDGE_ARTICLE',
  WEB_INTERACTIVE: 'WEB_INTERACTIVE',
  SMS: 'SMS',
  PODCAST: 'PODCAST',
  PODCAST_EPISODE: 'PODCAST_EPISODE'
};
export const ContentCategories = Immutable.Map({
  UNMAPPED: 0,
  LANDING_PAGE: 1,
  EMAIL: 2,
  BLOG_POST: 3,
  SITE_PAGE: 4,
  LEGACY_PAGE: 5,
  KNOWLEDGE_ARTICLE: 6,
  WEB_INTERACTIVE: 8,
  SMS: 10,
  CASE_STUDY: 13,
  PODCAST: 14,
  PODCAST_EPISODE: 15
});
export const ROOT_FOLDER_ID = 'root';
export const ImportImageCopyrightNoticeValues = {
  ACCEPTED: 'ACCEPTED'
};
export const ShutterstockTosValues = {
  ACCEPTED: 'ACCEPTED'
};
export const OptInStatus = {
  OptIn: 'OPT_IN',
  OptOut: 'OPT_OUT'
};
export const DiscoverabilityPopupDismissed = 'DISMISSED';
export const VideoProviders = keyMirror({
  HUBSPOT_VIDEO_2: null
});
export let Limits;
(function (Limits) {
  Limits["EMBEDDABLE_VIDEO"] = "EMBEDDABLE_VIDEO";
  Limits["MAX_FILE_UPLOAD_TOTAL"] = "MAX_FILE_UPLOAD_TOTAL";
})(Limits || (Limits = {}));
export const MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT = 40;
export const VIDEO_CAPACITY_PACKS_UPGRADE_THRESHOLD = 5;
export const IMG_WIDTH_IN_DETAIL_PANEL = 430;
export const PATH_TO_VIDEO_HOSTING_INFO = ['meta', 'video_data', 'hosting_infos'];
export const ImageEditorLocations = {
  DRAWER: 'DRAWER',
  EDITOR_MODAL: 'EDITOR_MODAL',
  IMAGE_GENERATION: 'IMAGE_GENERATION'
};
export const FILE_DETAILS_QUERY_PARAM_NAME = 'showDetails';
export const TRY_VIDEO_PATH = '/tryVideo';
export const HUSBPOT_VIDEO_PATH = '/hubspot-video';
export const ShutterstockFolderName = 'Stock images';
export const ShutterstockFolderPath = `/${ShutterstockFolderName}`;
export const PathToFileAccessibility = ['meta', 'allows_anonymous_access'];
export const PICKER_CHANGE_VISIBILITY_SOURCE = 'picker-change-visibility';
export const MODAL_WIDTH = 600;
// small enough to fit in the iframed picker with a bit of padding
export const PICKER_MODAL_WIDTH = 500;
export const VIDEO_CUT_OFF_DATE = Date.parse('02/21/2022');
export const LEARN_ABOUT_HS_VIDEO_LINK = 'https://knowledge.hubspot.com/website-pages/add-videos-to-your-hubspot-content';
export const HS_ACADEMY_VIDEO_MARKETING_URL = 'https://academy.hubspot.com/courses/video-marketing?source=files';
export const VIDEO_THUMB_ACCEPTED_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
export const IMAGE_GEN_V2_START_FROM_IMAGE_ACCEPTED_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'];
export const IMAGE_GENERATION_SQUARE_ONLY_APPS = ['podcast-editor-ui', 'podcast-management-ui', 'podcast-settings-ui'];
export const IMAGE_GENERATION_ENABLED_APPS = ['file-manager-demo', 'page-editor-ui', 'blog-editor-ui', 'EmailDragDropEditorUI', 'EmailTemplateEditorUI', 'sms-editor-ui', 'SocialUI', 'web-interactives-editor-ui', 'knowledge-base-editor-ui', ...IMAGE_GENERATION_SQUARE_ONLY_APPS];
export const UploadSource = {
  DASHBOARD: 'DASHBOARD',
  PICKER: 'PICKER'
};