import { fetchLocalServices } from '../apiClients/LocalhostApi';
import { getGlobal } from '../apiClients/global';
const window = getGlobal();
export function shouldUseLocalBackend() {
  if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
    // Note: This code requires browser localStorage. It will result in an error in Node.js environments like acceptance tests.
    try {
      const localChirpSetting = window.localStorage.getItem('LOCAL_CHIRP');
      return localChirpSetting === 'true';
    } catch (error) {
      console.error('Error accessing localStorage:', error);
      return false;
    }
  }
  return false;
}
export async function isLocalService(serviceName) {
  try {
    const services = await fetchLocalServices();
    const serviceFound = services.find(service => service.name === serviceName);
    return Boolean(serviceFound);
  } catch (error) {
    console.error('Error fetching local services:', error);
    return false;
  }
}