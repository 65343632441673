import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import quickFetch from 'quick-fetch';
const quickFetchEarlyRequestCache = url => {
  const earlyProgress = quickFetch.getRequestStateByName(url);
  if (earlyProgress) {
    return new Promise((resolve, reject) => {
      earlyProgress.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(url);
      });
      earlyProgress.onError(xhr => {
        if (xhr.status === 401) reject(xhr);else {
          resolve(noAuthApiClient.get(url));
        }
        quickFetch.removeEarlyRequest(url);
      });
    });
  }
  return noAuthApiClient.get(url);
};
export default quickFetchEarlyRequestCache;