import { AcademyFetchingClient } from 'academy-common-lib/utils/DataFetchingClient';
import Raven from 'raven-js';
export const onFetcherRejection = (reason, operationInfo) => {
  var _reason$status;
  Raven.captureMessage(`data-fetching-client ${operationInfo.operationType} error on ${operationInfo.fieldName}`, {
    extra: {
      dfcFieldName: operationInfo.fieldName,
      dfcOperationType: operationInfo.operationType,
      dfcVariables: operationInfo.variables,
      status: reason === null || reason === void 0 ? void 0 : reason.status,
      responseJSON: reason === null || reason === void 0 ? void 0 : reason.responseJSON,
      responseText: reason === null || reason === void 0 ? void 0 : reason.responseText
    },
    tags: {
      status: (reason === null || reason === void 0 || (_reason$status = reason.status) === null || _reason$status === void 0 ? void 0 : _reason$status.toString()) || 'unknown'
    }
  });
};
const dataFetchingClient = new AcademyFetchingClient({
  assumeImmutableResults: true,
  onFetcherRejection
});
export default dataFetchingClient;