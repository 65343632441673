import Raven from 'raven-js';
import events from '../../events.yaml';
import { createTracker } from 'usage-tracker-public';
const APP_NAME = 'academy-public-ui';
const waitForGlobal = (window, key, timeout = 100) => {
  const wait = resolve => {
    if (window[key]) {
      resolve(window[key]);
    } else {
      setTimeout(wait.bind(this, resolve), timeout);
    }
  };
  return new Promise(wait);
};
export class UsageTrackerPublic {
  constructor() {
    this.queue = [];
    this.consent = {
      allowed: false,
      categories: {
        necessary: true,
        analytics: false,
        advertisement: false,
        functionality: false
      }
    };
    this.lastScreen = undefined;
    this.tracker = createTracker({
      events,
      properties: {
        namespace: APP_NAME
      },
      onError: err => {
        Raven.captureException(err);
      }
    });
    void this.waitForCookieBannerQueue(window);
  }
  waitForCookieBannerQueue(win) {
    return waitForGlobal(win, '_hsp').then(_hsp => {
      _hsp.push(['addPrivacyConsentListener', consent => {
        this.consent = consent;
        if (consent.allowed) {
          this.drainQueue();
        }
      }]);
    }).catch(error => {
      Raven.captureException(error);
    });
  }
  queueEvent(eventName, props) {
    this.queue.push({
      eventName,
      props
    });
  }
  drainQueue() {
    while (this.queue.length > 0) {
      const event = this.queue.shift();
      if (event) {
        const {
          eventName,
          props
        } = event;
        this.tracker.track(eventName, props);
      }
    }
  }
  shouldQueue() {
    return !this.consent.allowed;
  }
  track(eventName, eventProps) {
    if (this.shouldQueue()) {
      this.queueEvent(eventName, eventProps);
    } else {
      this.tracker.track(eventName, eventProps);
    }
  }
  onError(err) {
    console.warn(err);
    Raven.captureException(err);
  }
}
const usageTracker = new UsageTrackerPublic();
export default usageTracker;