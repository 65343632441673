import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
const useSearchParams = () => {
  const history = useHistory();
  const location = useLocation();
  const setSearchParams = useCallback(params => {
    const newSearchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined || value === null || value === '') {
        newSearchParams.delete(key);
        return;
      }
      newSearchParams.set(key, value);
    });
    history.replace(Object.assign({}, location, {
      search: `?${newSearchParams}`
    }));
  }, [history, location]);
  const searchParams = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(location.search));
  }, [location.search]);
  return [searchParams, setSearchParams];
};
export default useSearchParams;